<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Remote Video Zone</strong>
            </div>
            <div class="text-muted">Edit Remote Video Zone information</div>
          </div>
          <div class="card-body">
            <a-form v-if="remoteVideoZoneData" :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote Video Zone's name.</span>
                  </template>
                  <a-input type="text" placeholder="Remote Video Zone Name" v-decorator="[ 'name', {
                  initialValue: remoteVideoZoneData.Name,
                  rules: [
                  { required: true, message: 'Name is required.' },
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Bids Daily Limit"
                           v-decorator="[ 'BidsDailyLimit', { initialValue: remoteVideoZoneData.BidsDailyLimit }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                           v-decorator="[ 'ClicksDailyLimit', { initialValue: remoteVideoZoneData.ClicksDailyLimit }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zone URL">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote Video Zone's URL.</span>
                  </template>
                  <a-input type="url" placeholder="Zone URL" v-decorator="['zoneUrl', {
                        initialValue: remoteVideoZoneData.ZoneUrl,
                        rules: [
                        { required: true, message: 'Zone URL is required.' },
                        { max: 512, message: 'Maximum 512 characters allowed.'}
                        ]}]">
                    <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                      <template slot="title">
                        <b>{cc}</b> - Lowercase country code will be replaced.<br>
                        <b>{CC}</b> - Uppercase country code will be replaced.<br>
                        <b>{Country}</b> - Capitalized country name will be replaced.<br>
                        <b>{ip}</b> - IP will be replaced.<br>
                        <b>{ua}</b> - User-agent string will be replaced.<br>
                        <b>{width}</b> - Width will be replaced.<br>
                        <b>{height}</b> - Height will be replaced.<br>
                        <b>{linearity}</b> - Linearity will be replaced.<br>
                        <b>{site_url}</b> - Site URL will be replaced.<br>
                        <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                        <b>{app_name}</b> - App Name will be replaced.<br>
                        <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                        <b>{bidfloor}</b> - Minimal CPM for the request.<br>
                        <b>{minduration}</b> - Minimal Video Ad duration in seconds.<br>
                        <b>{maxduration}</b> - Maximal Video Ad duration in seconds.<br>
                        <b>{category}</b> - Advertising category according to IAB classification.<br>
                        <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                        <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                        <b>{schain}</b> - Serialized schain parameter.<br>
                        <b>{lat}</b> - Latitude will be replaced.<br>
                        <b>{lon}</b> - Longitude will be replaced.<br>
                        <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                        <b>{cid}</b> - Click ID will be replaced.<br>
                      </template>
                      <a-icon type="code"/>
                    </a-tooltip>
                  </a-input>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote Video Zone's description.</span>
                  </template>
                  <a-input placeholder="Remote Video Zone Description" v-decorator="['description', {
              initialValue: remoteVideoZoneData.Description,
              rules: [
                  { max: 512, message: 'Maximum 512 characters allowed.'}
                ]}]"/>
                </a-tooltip>
              </a-form-item>

              <a-divider style="margin-top: 34px;">Supply</a-divider>

              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Video Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Video Zones to connect to the Remote Video Zone. This will make the Remote Video Zone appear for Video Zone if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Videozones', { initialValue: (remoteVideoZoneData.Videozones === '' ? [] : remoteVideoZoneData.Videozones)}]"
                    placeholder="Please select Video Zones"
                    :showSearch="true"
                    :filterOption="true"
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="obj in activeVideozones" class="zones" :key="obj.Id">[{{obj.Id}}] {{ obj.Name }}</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="RTB Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>RTB Zones to connect to the Remote Video Aone. This will make the Remote Video Zone appear in RTB Zone response if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Rtbzones', { initialValue: (remoteVideoZoneData.Rtbzones === '' ? [] : remoteVideoZoneData.Rtbzones)}]"
                    placeholder="Please select RTB Zones"
                    :showSearch="true"
                    :filterOption="true"
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="obj in activeRTBZones" class="zones" :key="obj.Id">[{{obj.Id}}] {{ obj.Name }}</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-divider style="margin-top: 34px;" class="mb-4">Companions</a-divider>
              <Companions :id="remoteVideoZoneData.Id" :type="'zone'" :creatives="bannerCreatives"
                          :companions="companions" :labelCol="labelCol" :wrapperCol="wrapperCol"/>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                          :class="'btn btn-'+(remoteVideoZoneData.Status === 0 ? 'danger' : 'primary')+' px-5 ml-0 ml-lg-2'"
                          @click.prevent="toggleZoneStatus">
                    {{ remoteVideoZoneData.Status == 0 ? 'Deactivate' : 'Activate' }} Remote Video Zone
                  </button>
                  <button type="button" class="btn px-5 ml-0 ml-lg-2" @click="goBack">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import { getCreatives } from '@/api/display/creative.js'
import { getRemoteVideoZones, changeRemoteVideoZoneStatus } from '@/api/display/remotevideozone.js'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 3 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 18 },
}

export default {
  components: {
    Companions: () => import('@/components/custom/companions/index.vue'),
  },
  computed: {
    ...mapState(['info', 'remotevideozone', 'rtbzone', 'videozone']),
    ...mapGetters('videozone', ['getVideozones', 'getActiveVideozones']),
    ...mapGetters('rtbzone', ['getRTBZones', 'getActiveRTBZones']),
    // initialValue() {
    //   if (this.$route.params.campaignId) {
    //     return this.ortbCampaign.list.filter(ele => ele.Id === parseInt(this.$route.params.campaignId))[0].Id
    //   } else {
    //     return []
    //   }
    // },
    activeVideozones: function () {
      return this.getActiveVideozones
    },
    activeRTBZones: function () {
      return this.getActiveRTBZones
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      checkedKeys: [],
      checkedAds: [],
      selectedAdKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      treeData: [],
      receivedCreatives: [],
      unfilteredCreatives: [],
      remoteVideoZoneData: null,
      bannerCreatives: [],
      unformattedResponse: [],
      OSData: null,
      labelCol,
      wrapperCol,
    }
  },
  methods: {
    onAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onBlockedAdCheck(checkedAds) {
      // console.log('onCheck', checkedAds)
      this.checkedAds = checkedAds
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({ name: 'display-remotevideozones' })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.zoneUrl !== '' && values.zoneUrl) {
            values.zoneUrl = values.zoneUrl.replace(/ /g, '')
          }
          this.$store.dispatch('remotevideozone/UPDATE_ZONE', {
            id: this.remoteVideoZoneData.Id,
            payload: { ...values },
            callback: function () {
              this.$router.push({ name: 'display-remotevideozones' })
            }.bind(this),
            // @click="$router.push('/ads')"
          })
        }
      })
    },
    $_loadCreatives() {
      getCreatives().then(response => {
        this.unfilteredCreatives = response
        this.unfilteredCreatives.map((el) => {
          if (el.Type === 0) {
            this.bannerCreatives.push(el)
          }
        })
      })
    },
    $_getContainer(el, tag = 'LI') {
      return el.tagName === tag ? el : this.$_getContainer(el.parentElement, tag)
    },
    toggleZoneStatus() {
      const status = this.remoteVideoZoneData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Remote Video Zone status?',
        content: h => <div>{status} Remote Video Zone <strong></strong>?</div>,
        onOk: function () {
          const newStatus = this.remoteVideoZoneData.Status === 0 ? 1 : 0
          changeRemoteVideoZoneStatus({
            id: this.remoteVideoZoneData.Id,
            status: newStatus,
          }).then((response) => {
            this.remoteVideoZoneData.Status = response.Remotevideozone.Status
            Vue.prototype.$notification.success({
              message: (response.Remotevideozone.Status === 2 ? 'Remote Video Zone Deleted' : 'Status Changed'),
              description: (response.Remotevideozone.Status === 2 ? 'Remote Video Zone successfully deleted.' : 'Remote Video Zone status successfully changed.'),
            })
          })
        }.bind(this),
      })
    },
  },
  watch: {
    'remoteVideoZoneData.Status'() {
    },
  },
  created() {
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    // this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
    // this.$store.dispatch('remotevideozone/LOAD_ZONES')
    getRemoteVideoZones(this.$route.params.id).then((response) => {
      this.remoteVideoZoneData = response
      this.companions = response.Companions
      this.$_loadCreatives()
    })
  },
}
</script>
<style lang="css" scoped>
.m-divider {
  margin-top: 34px;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th, .custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}
</style>
